<template>

    <div class="relative">

       <loader :loading="downloading"/>

        <div v-if="mensaje" class="bg-danger-30 text-danger rounded text-xs px-3 py-2px mt-1">
            {{ mensaje }}
        </div>

        <div v-if="from && to" class="text-white text-lg relative z-20 p-1">
            Downloading {{ from }} / {{ to }}
        </div>

        <div v-if="token_shocket && !from " class="text-white text-lg relative z-20 p-1">
            En cola
        </div>

        <i v-if="!token_shocket && !from && !to" class="mdi mdi-download text-2xl text-font-gray hover:text-font-light cursor-pointer" @click="print"></i>

        
        
    </div>

</template>

<script>
    import { state } from '@/store';

    export default {
        name: "ExcelDownloadButton",
        data() {
            return {
                name: '',
                error: false,
                mensaje: null,
                token_shocket: null,
                from: null,
                to: null,
                downloading: false
            };
        },
        computed: {
            globalParams() {
                return state.globalParams;
            }
        },
        methods: {
            async print() {
                this.downloading = true

                try {
                    const response = await this.axios.get('excel/generate', {
                        params: this.globalParams
                    });

                    this.token_shocket = response.data.token
                    
                } catch (error) {

                    this.error = true;
                    this.downloading = false

                }

            },
            async download() {
                try {
                    const response = await this.axios.get('excel/download', {
                        params: {
                            userId: state.user.id,
                            token: this.token_shocket,
                            excelFileName: 'ExecutiveDashboardDetails_'+state.user.id
                        },
                        responseType: 'blob',
                        headers: {
                            'Content-Type': 'application/json',
                            // 'Accept': 'application/vnd.ms-excel'
                        },
                    });

                    const blob = new Blob([response.data], {type: response.data.type});
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    var today = new Date();
                    var dd = today.getDate();
                    var mm = today.getMonth() + 1;
                    var yyyy = today.getFullYear();
                    var hour = today.getHours();
                    var minute = today.getMinutes();
                    link.setAttribute('download', 'ExecutiveDashboard_Details_' + yyyy + mm + dd +
                        '_' + hour + minute + '.zip');
                    link.click();

                    
                } catch (error) {
                    this.error = true;
                    this.percentage = 100;
                } finally {
                    this.token_shocket = null;
                    this.loadingExcel = false;
                }
            }
        },
        mounted() {
            
            window.Echo.channel( 'user.' + state.user.id.replaceAll('-', '') ).listen('App\\Zeus\\Operations\\Events\\Excel\\ProgressEvent', e => {
                
                if(e.data.status == 'inProgress'){

                    this.mensaje = null
                    this.downloading = true
                    this.from = e.data.start
                    this.to = e.data.end

                }

                if(e.data.status == 'download'){

                    this.downloading = false
                    this.from = null
                    this.to = null
              
                    this.download()

                }

                if(e.data.status == 'notRunning'){

                    this.downloading = false
                    this.mensaje = 'Something went wrong'
                }

            });
        },
    };

</script>

<style scoped>

</style>